import React, { useContext, useState } from 'react';
import { getToken } from '../../services/localStorage';
import { NotificationContext } from '../NotificationContext';
import SocketContext from './context';

const basePath = 'gwen.insertcoin.se';

export { SocketContext };

const SocketProvider = ({ children }) => {
  const { showNotification } = useContext(NotificationContext);
  const [isConnected, setIsConnected] = useState(false);

  const createSocket = async (token: string) => {
    try {
      const res = await fetch(`https://${basePath}/websocket/authorize`, {
        method: 'POST',
        headers: {
          authentication: token
        }
      });
      const { uws_ticket } = await res.json();

      return new WebSocket(`wss://${basePath}/websocket?ticket=${uws_ticket}`);
    } catch (error) {
      console.log(error);
    }
  };

  const start = async () => {
    const token = getToken();
    if (token && !isConnected) {
      const socket = await createSocket(token);

      socket.onmessage = (message) => {
        const { type, data } = JSON.parse(message.data);
        if (type === 'achievement-tierCompleted') {
          const { icon, title } = data.newState;
          showNotification(icon, title);
        }
      };
      setIsConnected(true);
    }
  };

  const providerValue = {
    start
  };

  return (
    <SocketContext.Provider value={providerValue}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
