import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from './src/components/shared/Layout';
import theme from './src/styles/theme';
import ErrorContextProvider from './src/contexts/ErrorContext';
import SocketContextProvider from './src/contexts/SocketContext';
import AudioContextProvider from './src/contexts/AudioContext';
import NotificationContextProvider from './src/contexts/NotificationContext';
import 'firebase/auth';
import 'firebase/database';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <ErrorContextProvider>
      <NotificationContextProvider>
        <SocketContextProvider>
          <AudioContextProvider>{element}</AudioContextProvider>
        </SocketContextProvider>
      </NotificationContextProvider>
    </ErrorContextProvider>
  </ThemeProvider>
);

export const wrapPageElement = ({ element, ...props }) => (
  <Layout {...props}>{element}</Layout>
);
