import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#0433FF',
    primaryDark: '#041331',
    primaryLight: '',
    secondary: '#C67028',
    secondaryLight: '#E59635',
    error: '#CC0000',
    textPrimary: '#FFFFFF',
    textSecondary: '#999B9C',
    button: '#292929',
    input: '#292929'
  },
  borders: {
    default: '1.5px solid #262C32'
  },
  modal: {
    marginTop: '48px'
  }
};

export default theme;
