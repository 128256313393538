import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import Spacer from './shared/Spacer';
import bpIcon from '../images/backpack.png';
import Breakpoints from '../services/breakpoints';

const height = '130';

const slideDown = keyframes`
  from { top: -${height}px; }
  to { top: 16px; }
`;
const slideUp = keyframes`
  from { top: 16px; }
  to { top: -${height}px; }
`;

const Container = styled.div<{ isVisible?: boolean }>`
  z-index: 999;
  position: absolute;
  top: -${height}px;
  left: 16px;
  width: calc(50% - 32px);
  background-color: #000;
  box-sizing: border-box;
  padding: 16px;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    right: 16px;
    width: auto;
  }
  ${(props) =>
    props.isVisible &&
    css`
      visibility: visible;
      top: 16px;
      animation: ${slideDown} 1s, ${slideUp} 1s 3s;
    `}
`;

const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
`;

const Icon = styled.img`
  height: 50px;
  width: 50px;
`;

const Heading = styled.p`
  color: grey;
`;

const Title = styled.p`
  color: #fff;
`;

const TextSmall = styled.p`
  font-size: 0.9rem;
  color: grey;
`;

const BackpackIcon = styled.img`
  height: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
`;

interface INotification {
  show: boolean;
  title?: string;
  icon?: string;
}

const Notification: React.FC<INotification> = ({ show, icon, title }) => (
  <Container isVisible={show}>
    {icon && <Icon src={icon} alt="notification image" />}
    <ContainerInner>
      <Heading>New Item!</Heading>
      {title && (
        <>
          <Spacer />
          <Title>{title}</Title>
        </>
      )}
      <Spacer />
      <TextSmall>...was added to your backpack</TextSmall>
    </ContainerInner>
    <BackpackIcon src={bpIcon} alt="backpack icon" />
  </Container>
);

export default Notification;
