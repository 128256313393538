import React, { useState } from 'react';
import Notification from '../../components/Notification';
import NotificationContext from './context';

export { NotificationContext };

const NotificationContextProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState('');

  const showNotification = (icon?: string, title?: string) => {
    setIcon(icon);
    setTitle(title);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3800);
  };

  const providerValue = {
    showNotification
  };

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
      <Notification show={show} icon={icon} title={title} />
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
