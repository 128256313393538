interface ICharacter {
  image: string;
  name: string;
  dialogue?: string;
}

interface IInput {
  value: string;
  nextRoomId: string;
  isPrimary?: boolean;
  audioPath: EPaths;
}

export interface IRoom {
  character?: ICharacter;
  output?: string;
  inputs: IInput[];
  setting: string;
  prompt?: string;
}

export interface IRegistrationFormFields {
  canContact: string;
  nickname: string;
  name: string;
  email: string;
  password?: string;
  fieldOfExpertise: string;
  yearsOfExperience: number | string;
  location: string;
  degree: string;
}

export interface ISignInFormFields {
  email: string;
  password: string;
}

export enum EPaths {
  NEUTRAL_BAD_PATH,
  GOOD_PATH,
  GOOD_GAME_END
}

export enum EAudioFileType {
  NEUTRAL_BAD_PATH,
  GOOD_PATH,
  GOOD_GAME_END,
  INTRO
}

export interface FirebaseUser {
  canContact: string;
  nickname: string;
  name: string;
  email: string;
  gwenId: string;
  fieldOfExpertise: string;
  yearsOfExperience: string;
  location: string;
  degree: string;
}
