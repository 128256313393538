import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface ISnackbar {
  show?: boolean;
}

const bottomMargin = '16px';

const fadein = keyframes`
  from {bottom: 0; opacity: 0;}
  to {bottom: ${bottomMargin}; opacity: 1;}
`;

const fadeout = keyframes`
  from {bottom: ${bottomMargin}; opacity: 1;}
  to {bottom: 0; opacity: 0;}
`;

const StyledSnackbar = styled.div<ISnackbar>`
  visibility: hidden;
  background-color: black;
  border: 1.5px solid ${({ theme }) => theme.colors.error};
  text-align: center;
  z-index: 1;
  height: 40px;
  left: 8px;
  right: 8px;
  bottom: ${bottomMargin};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      -webkit-animation: ${fadein} 0.5s, ${fadeout} 0.5s 2.5s;
      animation: ${fadein} 0.5s, ${fadeout} 0.5s 2.5s;
    `}
`;

const Snackbar: React.FC<ISnackbar> = (props) => <StyledSnackbar {...props} />;

export default Snackbar;
