import React, { useState } from 'react';
import Snackbar from '../../components/shared/Snackbar';
import ErrorContext from './context';

export { ErrorContext };

const ErrorProvider = (props) => {
  const [errorMessage, setErrorMessage] = useState(undefined);

  const handleError = (message: string) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const providerValue = {
    handleError
  };

  return (
    <ErrorContext.Provider value={providerValue}>
      {props.children}
      {errorMessage && (
        <Snackbar show={!!errorMessage}>{errorMessage}</Snackbar>
      )}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
