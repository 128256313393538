import React from 'react';
import GlobalStyle from '../../styles/GlobalStyle';

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
);

export default Layout;
