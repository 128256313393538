import { generateUUID } from './uuid';
import isBrowser from './isBrowser';

const PROFESSION_KEY = 'profession';
const PRODUCT_USER_KEY = 'userId';
const NAME_KEY = 'name';
const ROOM_KEY = 'room';
const FORM_KEY = 'form';
const TOKEN_KEY = 'tk';
const HAS_PLAYED_KEY = 'played';

export const saveProfession = (team: string) => {
  localStorage.setItem(PROFESSION_KEY, team);
};

export const getProfession = () =>
  isBrowser() && localStorage.getItem(PROFESSION_KEY);

export const saveProductUserId = (userId: string) => {
  localStorage.setItem(PRODUCT_USER_KEY, userId);
};

export const getProductUserId = () => {
  const userId = localStorage.getItem(PRODUCT_USER_KEY);
  if (userId) {
    return userId;
  }
  const newUserID = generateUUID();
  saveProductUserId(newUserID);
  return newUserID;
};

export const saveName = (name: string) => {
  localStorage.setItem(NAME_KEY, name);
};

export const getName = () => isBrowser() && localStorage.getItem(NAME_KEY);

export const saveCurrentRoom = (roomId: string) =>
  localStorage.setItem(ROOM_KEY, roomId);

export const getCurrentRoom = () =>
  isBrowser() && localStorage.getItem(ROOM_KEY);

export const removeCurrentRoom = () => localStorage.removeItem(ROOM_KEY);

export const saveFormData = (formData: any) => {
  localStorage.setItem(FORM_KEY, JSON.stringify(formData));
};

export const getFormData = () => {
  if (isBrowser()) {
    const formData = localStorage.getItem(FORM_KEY);
    return !!formData ? JSON.parse(formData) : undefined;
  }
};

export const removeFormData = () =>
  isBrowser() && localStorage.removeItem(FORM_KEY);

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => isBrowser() && localStorage.getItem(TOKEN_KEY);

export const saveHasPlayed = () => {
  localStorage.setItem(HAS_PLAYED_KEY, 'true');
};

export const getHasPlayed = () =>
  isBrowser() && localStorage.getItem(HAS_PLAYED_KEY);
