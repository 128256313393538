import React, { useState } from 'react';
import AudioContext from './context';
import introAudio from '../../audio/Intro.mp3';
import goodPathAudio from '../../audio/GoodPath.mp3';
import goodGameEndAudio from '../../audio/GoodGameEnd.mp3';
import neutralBadPathAudio from '../../audio/Neutral_BadPath.mp3';
import { EAudioFileType } from '../../models';

export { AudioContext };

const audioByType = {
  [EAudioFileType.INTRO]: introAudio,
  [EAudioFileType.GOOD_PATH]: goodPathAudio,
  [EAudioFileType.GOOD_GAME_END]: goodGameEndAudio,
  [EAudioFileType.NEUTRAL_BAD_PATH]: neutralBadPathAudio
};

const initialValues = {
  [EAudioFileType.INTRO]: false,
  [EAudioFileType.GOOD_PATH]: false,
  [EAudioFileType.GOOD_GAME_END]: false,
  [EAudioFileType.NEUTRAL_BAD_PATH]: false
};

const AudioContextProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(initialValues);
  const [audioPlayer, setAudioPlayer] = useState(null);

  const play = (type: EAudioFileType) => {
    if (!isPlaying[type]) {
      setIsPlaying({
        ...initialValues,
        [type]: true
      });
      if (audioPlayer) {
        audioPlayer.pause();
      }
      const audio = new Audio(audioByType[type]);
      if (audio) {
        audio.loop = true;
        audio.play();
        setAudioPlayer(audio);
      }
    }
  };

  const stop = () => {
    audioPlayer?.pause();
  };

  const providerValue = {
    play,
    stop
  };
  return (
    <AudioContext.Provider value={providerValue}>
      {children}
    </AudioContext.Provider>
  );
};

export default AudioContextProvider;
