import { createGlobalStyle } from 'styled-components';
import Breakpoints from '../services/breakpoints';
import font from './fonts/VT323-Regular.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Gaming';
    src: url(${font});
    font-weight: 400;
    font-style: normal;
  }
  * {
    font-size: 16px;
    font-family: 'Gaming';
    line-height: 1.1;
    letter-spacing: 1.5px;
    @media screen and (min-width: ${Breakpoints.MOBILE}) {
      font-size: 20px;
    }
  }
  body {
    margin: 0;
    max-width: 100%;
    cursor: default;
    background-color: black;
    color: lightgrey;
    display: block;
    margin: auto;
    position: relative;
    max-width: ${Breakpoints.DESKTOP};
  }
  input, button {
    outline: none;
    width: 100%;
    min-height: 40px;
    box-sizing: border-box;
    padding 8px;
    color: white;
    border: none;
    background-color: #292929;
  }
  p {
    margin: 0;
  }
`;
