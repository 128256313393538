import React from 'react';

interface SpacerProps {
  orientation?: 'vertical' | 'horizontal';
  spacing?: number;
}

const Spacer: React.FC<SpacerProps> = ({
  orientation = 'vertical',
  spacing = 1
}) => (
  <div
    style={{
      [orientation === 'vertical' ? 'height' : 'width']: spacing * 8
    }}
  />
);

export default Spacer;
