// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-backpack-tsx": () => import("./../../../src/pages/backpack.tsx" /* webpackChunkName: "component---src-pages-backpack-tsx" */),
  "component---src-pages-end-tsx": () => import("./../../../src/pages/end.tsx" /* webpackChunkName: "component---src-pages-end-tsx" */),
  "component---src-pages-game-tsx": () => import("./../../../src/pages/game.tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-highscore-tsx": () => import("./../../../src/pages/highscore.tsx" /* webpackChunkName: "component---src-pages-highscore-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profession-tsx": () => import("./../../../src/pages/profession.tsx" /* webpackChunkName: "component---src-pages-profession-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */)
}

